/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListExpenseLineItemsOrdering =
  (typeof ListExpenseLineItemsOrdering)[keyof typeof ListExpenseLineItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListExpenseLineItemsOrdering = {
  description: 'description',
  '-description': '-description',
  schedule_of_values_item_number: 'schedule_of_values_item_number',
  '-schedule_of_values_item_number': '-schedule_of_values_item_number',
  amount: 'amount',
  '-amount': '-amount',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
