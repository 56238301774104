/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListExpandedExpensesOrdering =
  (typeof ListExpandedExpensesOrdering)[keyof typeof ListExpandedExpensesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListExpandedExpensesOrdering = {
  payee_business_name: 'payee_business_name',
  '-payee_business_name': '-payee_business_name',
  date: 'date',
  '-date': '-date',
  no_project: 'no_project',
  '-no_project': '-no_project',
  hidden_from_non_employees: 'hidden_from_non_employees',
  '-hidden_from_non_employees': '-hidden_from_non_employees',
  is_void: 'is_void',
  '-is_void': '-is_void',
  approval_state: 'approval_state',
  '-approval_state': '-approval_state',
  from_business_name: 'from_business_name',
  '-from_business_name': '-from_business_name',
  from_business_address: 'from_business_address',
  '-from_business_address': '-from_business_address',
  to_business_name: 'to_business_name',
  '-to_business_name': '-to_business_name',
  to_business_address: 'to_business_address',
  '-to_business_address': '-to_business_address',
  invoice_type: 'invoice_type',
  '-invoice_type': '-invoice_type',
  contract_seqno: 'contract_seqno',
  '-contract_seqno': '-contract_seqno',
  total_amount: 'total_amount',
  '-total_amount': '-total_amount',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
  vendor_name: 'vendor_name',
  '-vendor_name': '-vendor_name',
  has_receipt: 'has_receipt',
  '-has_receipt': '-has_receipt',
} as const
