/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListPrimeContractInvoiceEmailsOrdering =
  (typeof ListPrimeContractInvoiceEmailsOrdering)[keyof typeof ListPrimeContractInvoiceEmailsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListPrimeContractInvoiceEmailsOrdering = {
  send_attempted_at: 'send_attempted_at',
  '-send_attempted_at': '-send_attempted_at',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
