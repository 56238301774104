/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InternalFileUploadType =
  (typeof InternalFileUploadType)[keyof typeof InternalFileUploadType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalFileUploadType = {
  generic: 'generic',
  cost_code: 'cost_code',
  cost_type: 'cost_type',
  cost_library: 'cost_library',
  estimate: 'estimate',
} as const
