/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInvoiceApprovalActionsOrdering =
  (typeof ListInvoiceApprovalActionsOrdering)[keyof typeof ListInvoiceApprovalActionsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInvoiceApprovalActionsOrdering = {
  seqno: 'seqno',
  '-seqno': '-seqno',
  type: 'type',
  '-type': '-type',
  proposed_amount: 'proposed_amount',
  '-proposed_amount': '-proposed_amount',
  comments: 'comments',
  '-comments': '-comments',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
