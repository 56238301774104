/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CreateSlackPostViewInputChannel =
  (typeof CreateSlackPostViewInputChannel)[keyof typeof CreateSlackPostViewInputChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSlackPostViewInputChannel = {
  'eng-asks': 'eng-asks',
  'thomas-test': 'thomas-test',
} as const
