/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SaasSubscriptionPatchTier =
  (typeof SaasSubscriptionPatchTier)[keyof typeof SaasSubscriptionPatchTier]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaasSubscriptionPatchTier = {
  plus: 'plus',
  scale: 'scale',
  plus_with_coi: 'plus_with_coi',
  core: 'core',
} as const
