/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListAccountsOrdering = (typeof ListAccountsOrdering)[keyof typeof ListAccountsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAccountsOrdering = {
  public_token: 'public_token',
  '-public_token': '-public_token',
  account_token: 'account_token',
  '-account_token': '-account_token',
  company_id: 'company_id',
  '-company_id': '-company_id',
  outbound_sync_enabled: 'outbound_sync_enabled',
  '-outbound_sync_enabled': '-outbound_sync_enabled',
  enabled: 'enabled',
  '-enabled': '-enabled',
  token_status: 'token_status',
  '-token_status': '-token_status',
  initial_project_import_complete: 'initial_project_import_complete',
  '-initial_project_import_complete': '-initial_project_import_complete',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
