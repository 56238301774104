/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AdminEmailFailureReason =
  (typeof AdminEmailFailureReason)[keyof typeof AdminEmailFailureReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminEmailFailureReason = {
  none: 'none',
  blocked_domain: 'blocked_domain',
  idempotency_key_conflict: 'idempotency_key_conflict',
  exception: 'exception',
} as const
