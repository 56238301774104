/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SetupIntentVerificationMethod =
  (typeof SetupIntentVerificationMethod)[keyof typeof SetupIntentVerificationMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetupIntentVerificationMethod = {
  automatic: 'automatic',
  skip: 'skip',
  instant: 'instant',
  null: null,
} as const
