/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListAdminEmailsOrdering =
  (typeof ListAdminEmailsOrdering)[keyof typeof ListAdminEmailsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAdminEmailsOrdering = {
  subject: 'subject',
  '-subject': '-subject',
  recipient: 'recipient',
  '-recipient': '-recipient',
  reply_to: 'reply_to',
  '-reply_to': '-reply_to',
  email_type: 'email_type',
  '-email_type': '-email_type',
  send_attempted_at: 'send_attempted_at',
  '-send_attempted_at': '-send_attempted_at',
  send_confirmed: 'send_confirmed',
  '-send_confirmed': '-send_confirmed',
  failure_reason: 'failure_reason',
  '-failure_reason': '-failure_reason',
  sender_name: 'sender_name',
  '-sender_name': '-sender_name',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
