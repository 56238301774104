import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { useOnMount } from 'msutils'

const DefaultScrollParent = 'BODY'

// https://github.com/codastic/react-positioning-portal/blob/master/src/services/scroll-parents.ts
function isScrollParent(element: HTMLElement): boolean {
  try {
    const { overflow, overflowY, overflowX } = getComputedStyle(element)
    return /(auto|scroll)/.test(overflow + overflowX + overflowY)
  } catch (e) {
    return false
  }
}

export function getScrollableParents(
  element: HTMLElement | null,
  scrollParents: Array<HTMLElement> = [],
): Array<HTMLElement> {
  if (!element || element.tagName === DefaultScrollParent) {
    return scrollParents
  }

  return getScrollableParents(
    element.parentElement,
    isScrollParent(element) ? [...scrollParents, element] : scrollParents,
  )
}

export function getParents(
  element: HTMLElement | null,
  parents: Array<HTMLElement> = [],
): Array<HTMLElement> {
  if (!element || element.tagName === DefaultScrollParent) {
    return [...parents, document.body]
  }
  return getParents(element.parentElement, [...parents, element])
}

export function useElementSize(element: HTMLElement | null) {
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(
    element ? element.getBoundingClientRect() : null,
  )
  useEffect(() => {
    if (element) {
      const handleResize = () => {
        setBoundingRect(element.getBoundingClientRect())
      }

      const observer = new ResizeObserver(handleResize)
      observer.observe(element)
      return () => {
        observer.disconnect()
      }
    } else {
      return undefined
    }
  }, [element])
  return boundingRect
}

export function keyPressIsEnter(e: any) {
  return e.code === 'Enter'
}

export function useOnWindowLoseFocus(callback: () => void) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback
  useOnMount(() => {
    const handle = () => callbackRef.current()
    window.addEventListener('blur', handle)
    return () => {
      window.removeEventListener('blur', handle)
    }
  })
}

export function useOnResize(els: HTMLElement[], callback_: () => void) {
  const callbackRef = useRef(callback_)
  callbackRef.current = callback_
  useOnMount(() => {
    const callback = () => callbackRef.current()
    let frame: number
    const resizeObserver = new ResizeObserver(() => {
      // make this only fire once per animation frame
      if (frame) cancelAnimationFrame(frame)
      frame = requestAnimationFrame(callback)
    })
    els.forEach((el) => resizeObserver.observe(el))
    return () => {
      resizeObserver.disconnect()
    }
  })
}

export function useOnScroll(el: HTMLElement, callback_: () => void) {
  const callbackRef = useRef(callback_)
  callbackRef.current = callback_
  useOnMount(() => {
    const callback = () => callbackRef.current()
    window.addEventListener('scroll', callback)
    const scrollableParents = getScrollableParents(el)
    scrollableParents.forEach((sp) => sp.addEventListener('scroll', callback))
    return () => {
      window.removeEventListener('resize', callback)
      window.addEventListener('scroll', callback)
      window.visualViewport?.removeEventListener('resize', callback)
      scrollableParents.forEach((sp) => sp.removeEventListener('scroll', callback))
    }
  })
}

export function useOnAnyMovement(el: HTMLElement, callback_: () => void) {
  const callbackRef = useRef(callback_)
  callbackRef.current = callback_
  const elements = useMemo(() => [el, ...getParents(el)], [el])
  useOnResize(elements, callback_)
  useOnMount(() => {
    const callback = () => callbackRef.current()
    window.addEventListener('resize', callback)
    return () => {
      window.removeEventListener('resize', callback)
    }
  })
}

function reactStyleToDomStyle(props: CSSProperties) {
  return Object.entries(props)
    .map(([key, value]) => {
      const property = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
      return `${property}: ${value};`
    })
    .join('\n')
}

export function Style(props: { selector: string } & CSSProperties) {
  const s = useMemo(() => {
    const styles = Object.fromEntries(
      Object.entries(props).flatMap(([k, v]) => (k === 'selector' ? [] : [[k, v]])),
    )
    return `${props.selector} {\n${reactStyleToDomStyle(styles)}\n}`
  }, [props])
  return <style>{s}</style>
}
