/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BusinessOnHoldReason = (typeof BusinessOnHoldReason)[keyof typeof BusinessOnHoldReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessOnHoldReason = {
  newly_onboarded: 'newly_onboarded',
  is_suspicious: 'is_suspicious',
  payouts_disabled: 'payouts_disabled',
  null: null,
} as const
