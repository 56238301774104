import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { z } from 'zod'
import { useStorageState } from './common/storage'
import { unreachable } from './misc'
import { useOnChange, useStabilized } from './common/hooks'
import { useOnMount } from './common'

type AuthStrategy = 'local' | 'session'
type AuthSettings = { impersonator: boolean }
type AuthState = Partial<AuthSettings> & { header: string }

const schema = z
  .object({ header: z.string() })
  .and(
    z
      .object({ impersonator: z.boolean(), strategy: z.enum(['local', 'session', 'state']) })
      .partial(),
  )

const transforms = {
  transformIn: (strValue: string) => schema.parse(JSON.parse(strValue)),
  transformOut: (value: AuthState) => JSON.stringify(value),
}

type PreAuthContext<TPath> = {
  setAuth: (
    token: string,
    strategy: AuthStrategy,
    settings?: Partial<AuthSettings & { redirectPath: TPath }>,
  ) => void
  resetAuth: () => void
}

type TAuthContext<TPath> = PreAuthContext<TPath> &
  (
    | {
        isAuthed: true
        token: string
        strategy: AuthStrategy
        settings: AuthSettings
      }
    | {
        isAuthed: false
        strategy: null
        token: null
        settings: null
      }
  )

type AuthContextProps = {
  onAuth?: (token: string) => void
  onDeAuth?: () => void
  unAuthedContent: ReactNode
  children: ReactNode
}

type AuthManager<TPath> = {
  Context: FC<AuthContextProps>
  usePreAuthContext: () => TAuthContext<TPath>
  usePostAuthContext: () => TAuthContext<TPath> & { isAuthed: true }
  getAuthDONOTUSE: () => { impersonator: boolean } | null
  clearAuthDONOTUSE: () => void
}

export function Auth<TPath>(props: {
  storageKey: string
  usePath: () => TPath
  useSetPath: () => (path: TPath) => void
  defaultPostAuthPath: TPath
  defaultPreAuthPath: TPath
  allowUnauthed: (path: TPath) => boolean
  banAuthed: (path: TPath) => boolean
  ignoreAuth?: (path: TPath) => boolean
}): AuthManager<TPath> {
  if (!props.allowUnauthed(props.defaultPreAuthPath)) {
    throw new Error('Default unauthed route needs to be allowed unauthed')
  }

  const AuthContext = createContext<TAuthContext<TPath> | undefined>(undefined)

  function getAuthDONOTUSE() {
    const session = sessionStorage.getItem(props.storageKey)
    const local = localStorage.getItem(props.storageKey)
    const parsed = schema.safeParse(JSON.parse(session ?? local ?? '{}'))
    if (parsed.success) {
      return { impersonator: parsed.data.impersonator ?? false }
    } else {
      return null
    }
  }

  function clearAuthDONOTUSE() {
    localStorage.removeItem(props.storageKey)
    sessionStorage.removeItem(props.storageKey)
  }

  function Context({ onAuth, onDeAuth, unAuthedContent, children }: AuthContextProps) {
    const path = props.usePath()
    const setPath = props.useSetPath()
    const stableOnAuth = useStabilized(onAuth)
    const stableOnDeAuth = useStabilized(onDeAuth)
    const [localAuth, setLocalAuth] = useStorageState(props.storageKey, 'local', transforms)
    const [sessionAuth, setSessionAuth] = useStorageState(props.storageKey, 'session', transforms)
    const [deepLink, setDeepLink] = useState<TPath | null>(null)
    const [hasTriggeredAuthEffects, setHasTriggeredAuthEffects] = useState(false)

    const resetAuth = useCallback(async () => {
      setHasTriggeredAuthEffects(false)
      await Promise.resolve(setPath(props.defaultPreAuthPath))
      setLocalAuth(null)
      setSessionAuth(null)
      setDeepLink(null)
      stableOnDeAuth.current?.()
    }, [setLocalAuth, setSessionAuth, setPath, stableOnDeAuth])

    const setAuth: TAuthContext<TPath>['setAuth'] = useCallback(
      (header, strategy, settings) => {
        if (strategy === 'session') {
          setSessionAuth({ header, impersonator: settings?.impersonator })
        } else if (strategy === 'local') {
          setLocalAuth({ header, impersonator: settings?.impersonator })
          setSessionAuth(null)
        } else {
          unreachable(strategy)
        }
        setPath(settings?.redirectPath ?? deepLink ?? props.defaultPostAuthPath)
        stableOnAuth.current?.(header)
        setHasTriggeredAuthEffects(true)
      },
      [setSessionAuth, setLocalAuth, setPath, stableOnAuth, deepLink],
    )

    const resolvedAuth = useMemo((): TAuthContext<TPath> => {
      if (props.ignoreAuth?.(path)) {
        return { isAuthed: false, token: null, strategy: null, settings: null, setAuth, resetAuth }
      }

      if (sessionAuth) {
        return {
          isAuthed: true,
          token: sessionAuth.header,
          strategy: 'session',
          settings: { impersonator: sessionAuth.impersonator ?? false },
          setAuth,
          resetAuth,
        }
      } else if (localAuth) {
        return {
          isAuthed: true,
          token: localAuth.header,
          strategy: 'session',
          settings: { impersonator: localAuth.impersonator ?? false },
          setAuth,
          resetAuth,
        }
      } else {
        return { isAuthed: false, token: null, strategy: null, settings: null, setAuth, resetAuth }
      }
    }, [path, resetAuth, setAuth, localAuth, sessionAuth])

    useOnMount(() => {
      if (!props.ignoreAuth?.(path)) {
        if (resolvedAuth.isAuthed) {
          stableOnAuth.current?.(resolvedAuth.token)
          setHasTriggeredAuthEffects(true)
          if (props.banAuthed(path)) setPath(props.defaultPostAuthPath)
        } else {
          if (!props.allowUnauthed(path)) {
            setDeepLink(path)
            setPath(props.defaultPreAuthPath)
          }
        }
      }
    })

    useOnChange([path], () => {
      if (!props.ignoreAuth?.(path)) {
        if (resolvedAuth.isAuthed) {
          if (props.banAuthed(path)) setPath(props.defaultPostAuthPath)
        } else {
          if (!props.allowUnauthed(path)) setPath(props.defaultPreAuthPath)
        }
      }
    })

    if (resolvedAuth.isAuthed && !hasTriggeredAuthEffects) return null
    if (!resolvedAuth.isAuthed && !props.allowUnauthed(path)) return null
    if (resolvedAuth.isAuthed && props.banAuthed(path)) return null

    return (
      <AuthContext.Provider value={resolvedAuth}>
        {resolvedAuth.isAuthed && !props.ignoreAuth?.(path) ? children : unAuthedContent}
      </AuthContext.Provider>
    )
  }

  function usePostAuthContext() {
    const ctx = useContext(AuthContext)
    if (!ctx) throw new Error('AuthContext must be used within the provider')
    if (!ctx.isAuthed) throw new Error('You are not authenticated')
    return ctx
  }

  function usePreAuthContext() {
    const ctx = useContext(AuthContext)
    if (!ctx) throw new Error('AuthContext must be used within the provider')
    return ctx
  }

  return {
    Context,
    usePostAuthContext,
    usePreAuthContext,
    getAuthDONOTUSE,
    clearAuthDONOTUSE,
  }
}
