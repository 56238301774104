import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'
import { emptyList, useOnChange } from 'msutils'
import { getStorageManager, useStorageState } from 'msutils/common/storage'
import { z } from 'zod'
import { auth } from './auth'
import { useAppContext } from './user'

// Note: right now, the auth switch is meant to live inside the auth wall, which means you can only access it if you're authed as something. In the future, I'm sure we'll want it outside the auth wall, but that would require changing the "single auth manager" to allow non-context based access. (or maybe switch auth manager to have both an outer context and an inner context)

const schema = z
  .array(
    z.object({
      businessId: z.string(),
      businessName: z.string(),
      userId: z.string(),
      username: z.string(),
      token: z.string(),
    }),
  )
  .default([])

type AuthEntry = z.infer<typeof schema>[number]

const key = '__AUTH_CACHE__'

const transforms = {
  transformIn: (strValue: string) => schema.parse(JSON.parse(strValue || '[]')),
  transformOut: (val: AuthEntry[]) => JSON.stringify(val),
}

export const authSwitcherStorageManager = getStorageManager(key, 'local', transforms)

type AuthSwitcherContext = {
  authedBusinesses: AuthEntry[]
  switchAuthEntry: (businessId: string, userId: string) => void
  removeAuthEntry: (businessId: string, userId: string) => void
}
const Ctx = createContext<AuthSwitcherContext | undefined>(undefined)

export function useAuthSwitcherContext() {
  const ctx = useContext(Ctx)
  if (!ctx) throw new Error('AuthSwitcher context error')
  return ctx
}

export function AuthSwitcher({ children }: { children: ReactNode }) {
  const appContext = useAppContext()
  const storageState = useStorageState(key, 'local', transforms)
  const state = storageState[0] ?? emptyList
  const setState = storageState[1]
  const { setAuth, strategy, settings, token } = auth.usePostAuthContext()

  const matches = useCallback(
    ({ businessId, userId, entry }: { businessId: string; userId: string; entry: AuthEntry }) =>
      entry.businessId === businessId && entry.userId === userId,
    [],
  )

  const ctxValue = useMemo(
    (): AuthSwitcherContext => ({
      authedBusinesses: state,
      removeAuthEntry: (businessId, userId) =>
        setState(state.filter((x) => !matches({ businessId, userId, entry: x }))),
      switchAuthEntry: (businessId, userId) => {
        const newAuth = state.find((x) => matches({ businessId, userId, entry: x }))
        if (newAuth) {
          setAuth(newAuth.token, strategy, settings)
        }
      },
    }),
    [state, setState, strategy, settings, matches, setAuth],
  )

  useOnChange([token], () => {
    const business = appContext.type === 'business' ? appContext.business : appContext.client
    const businessName =
      appContext.type === 'business' ? appContext.business.name : appContext.client.business_name
    const user = appContext.user
    const alreadyExistsInStorage = !!state.find((x) =>
      matches({ businessId: business.id, userId: user.id, entry: x }),
    )
    if (!alreadyExistsInStorage) {
      setState([
        ...state,
        { businessId: business.id, businessName, userId: user.id, username: user.full_name, token },
      ])
    }
  })

  return <Ctx.Provider value={ctxValue}>{children}</Ctx.Provider>
}
