import { createContext, FC, ReactNode, useContext } from 'react'

export function ContextBuilder<T extends object>(defaultValue?: Partial<T>) {
  const Ctx = createContext<Partial<T>>(defaultValue ?? {})
  const Provider: FC<{ children: ReactNode; value: Partial<T> }> = function MProvider(props) {
    return <Ctx.Provider value={props.value}>{props.children}</Ctx.Provider>
  }

  return { Provider, useContext: () => useContext(Ctx) }
}

type Cb2<T> = {
  Provider: FC<{ children: ReactNode }>
  useContext: () => T
  useOptionalContext: () => T | undefined
}

export function ContextBuilder2<T>(
  useSetup: () => T,
  props?: {
    defaultValue?: T
  },
): Cb2<T> {
  const Ctx = createContext<T | undefined>(undefined)
  return {
    Provider: ({ children }) => {
      const ctxValue = useSetup()
      return <Ctx.Provider value={ctxValue}>{children}</Ctx.Provider>
    },
    useContext: () => {
      const ctx = useContext(Ctx) ?? props?.defaultValue
      if (!ctx) throw new Error('Error in context builder')
      return ctx
    },
    useOptionalContext: () => useContext(Ctx) ?? props?.defaultValue,
  }
}
