/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type RenofiAdNoOfferReason =
  (typeof RenofiAdNoOfferReason)[keyof typeof RenofiAdNoOfferReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RenofiAdNoOfferReason = {
  amount_too_low: 'amount_too_low',
  not_homeowner: 'not_homeowner',
  no_published_estimate: 'no_published_estimate',
  state_not_supported: 'state_not_supported',
  not_enabled: 'not_enabled',
  null: null,
} as const
