/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimatePageVisitsOrdering =
  (typeof ListEstimatePageVisitsOrdering)[keyof typeof ListEstimatePageVisitsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimatePageVisitsOrdering = {
  timestamp: 'timestamp',
  '-timestamp': '-timestamp',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
