/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ExpenseCardSpendingLimitPeriod =
  (typeof ExpenseCardSpendingLimitPeriod)[keyof typeof ExpenseCardSpendingLimitPeriod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseCardSpendingLimitPeriod = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  lifetime: 'lifetime',
  null: null,
} as const
