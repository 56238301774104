/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListSaasSubscriptionsOrdering =
  (typeof ListSaasSubscriptionsOrdering)[keyof typeof ListSaasSubscriptionsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListSaasSubscriptionsOrdering = {
  amount: 'amount',
  '-amount': '-amount',
  start_date: 'start_date',
  '-start_date': '-start_date',
  tier: 'tier',
  '-tier': '-tier',
  is_external: 'is_external',
  '-is_external': '-is_external',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
