/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListUsersOrdering = (typeof ListUsersOrdering)[keyof typeof ListUsersOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListUsersOrdering = {
  email: 'email',
  '-email': '-email',
  phone_number: 'phone_number',
  '-phone_number': '-phone_number',
  is_admin: 'is_admin',
  '-is_admin': '-is_admin',
  password: 'password',
  '-password': '-password',
  full_name: 'full_name',
  '-full_name': '-full_name',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
