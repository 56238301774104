/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AdminQBRequestType = (typeof AdminQBRequestType)[keyof typeof AdminQBRequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminQBRequestType = {
  push: 'push',
  pull: 'pull',
} as const
