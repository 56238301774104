/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SetSimpleConfigValueViewInputType =
  (typeof SetSimpleConfigValueViewInputType)[keyof typeof SetSimpleConfigValueViewInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetSimpleConfigValueViewInputType = {
  expense_code_strategy: 'expense_code_strategy',
  on_hold: 'on_hold',
} as const
