/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListTransfersOrdering =
  (typeof ListTransfersOrdering)[keyof typeof ListTransfersOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListTransfersOrdering = {
  amount: 'amount',
  '-amount': '-amount',
  state: 'state',
  '-state': '-state',
  initiated_date: 'initiated_date',
  '-initiated_date': '-initiated_date',
  completed_date: 'completed_date',
  '-completed_date': '-completed_date',
  timestamp: 'timestamp',
  '-timestamp': '-timestamp',
  external: 'external',
  '-external': '-external',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const
