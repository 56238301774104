import { Module, RawRoute } from 'modules/routes'
import { MosaicHref } from 'modules/routes/types'
import { MSAuth } from 'msutils'
import { useCurrentPath, useNavigate } from 'utils/router'

const unauthedRoutes = [
  /\/sign-up/,
  /\/login/,
  /\/forgot-password/,
  /\/password_reset/,
  /\/employee-sign-up/,
]

const ignoreRoutes = [/\/redirect/, /\/r\//]

export function isUnauthedRoute(route: string) {
  return unauthedRoutes.some((x) => x.test(route))
}

export const auth = MSAuth<MosaicHref>({
  defaultPreAuthPath: Module('/login').href,
  defaultPostAuthPath: Module('/').href,
  usePath: () => {
    const path = useCurrentPath()
    return RawRoute(path)
  },
  useSetPath: () => {
    const navigate = useNavigate()
    return (path) => navigate(path)
  },
  storageKey: '__AUTH__',
  allowUnauthed: (path) =>
    unauthedRoutes.some((x) => x.test(path)) || ignoreRoutes.some((x) => x.test(path)),
  banAuthed: (path) => unauthedRoutes.some((x) => x.test(path)),
  ignoreAuth: (path) => ignoreRoutes.some((x) => x.test(path)),
})
