/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CustomerSummaryViewOutputResultsItemStripeIdentityVerificationSessionStatus =
  (typeof CustomerSummaryViewOutputResultsItemStripeIdentityVerificationSessionStatus)[keyof typeof CustomerSummaryViewOutputResultsItemStripeIdentityVerificationSessionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerSummaryViewOutputResultsItemStripeIdentityVerificationSessionStatus = {
  canceled: 'canceled',
  processing: 'processing',
  requires_input: 'requires_input',
  verified: 'verified',
  null: null,
} as const
