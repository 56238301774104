import { useEffect, useRef } from 'react'

export function useOnChange(deps: any[], fn: () => any) {
  const fnRef = useRef(fn)
  fnRef.current = fn
  useEffect(() => {
    fnRef.current()
    // eslint-disable-next-line
  }, deps)
}

export function useControlledOrUncontrolled<T>(
  controlledValue: T | undefined,
  uncontrolledValue: T,
) {
  const initialControlled = useRef(controlledValue).current
  if (initialControlled) {
    if (controlledValue) {
      return controlledValue
    } else {
      // eslint-disable-next-line
      console.error('Component changed from controlled to uncontrolled')
      return uncontrolledValue
    }
  } else if (controlledValue) {
    // eslint-disable-next-line
    console.error('Component changed from uncontrolled to controlled')
    return controlledValue
  } else {
    return uncontrolledValue
  }
}

export function useStabilized<T>(value: T) {
  const ref = useRef(value)
  ref.current = value
  return ref
}
