/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SetupIntentPatchVerificationMethod =
  (typeof SetupIntentPatchVerificationMethod)[keyof typeof SetupIntentPatchVerificationMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetupIntentPatchVerificationMethod = {
  automatic: 'automatic',
  skip: 'skip',
  instant: 'instant',
  null: null,
} as const
